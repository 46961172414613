import Cookies from "js-cookie";
import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes";
import { COOKIE_SESION_TKN } from "../utils/constantes";
import store from "../store";
import { getCurrentUser } from "../services/auth/authServices";
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

/**
 * Middleware
 */

router.beforeEach(async (to, _, next) => {
    const sesionToken = Cookies.get(COOKIE_SESION_TKN);
    let userData = store.state.User.user;
    if (!sesionToken) {
        // Si no hay un token de sesión
        if (to.meta.requiredAuth) {
            // Si la vista tiene permisos redirecciona
            next({ name: "Login" });
        } else {
            next();
        }
    } else {
        // Si tiene un token
        if (to.meta.requiredAuth) {
            
            // Si es una vista con permisos
            if (!userData) {
                // Si no tenemos la info del usuario autenticado
                const response = await getCurrentUser();
                if (response.status === 200) {
                    // Si obtiene correctamente el usuario
                    store.dispatch("User/setUser", response.data);
                } else {
                    console.log("logout");
                    // Si su token no es valido saca la sesión
                    store.dispatch("User/logout");
                    if (response.status !== 401) next({ name: "Login" }); // 401 ya hace la redirección a "/"
                    if (response.status === 404) next({ name: "Login" }); // 401 ya hace la redirección a "/"
                }
            }
            next();
        } else {
            // Si la vista no esta protegida debe ir al perfil
            console.log("asda")
            next("perfil");
        }
    }
});

export default router;
