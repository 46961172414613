<template>
    <app-layout>
        <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in" :key="route.name">
                <component :is="Component"></component>
            </transition>
        </router-view>
        <!-- <vue-progress-bar></vue-progress-bar> -->
    </app-layout>
    <installation-banner></installation-banner>
</template>

<script>
import AppLayout from './layouts/AppLayout.vue'
import InstallationBanner from './InstallationBanner.vue';
export default {
    components: {
        AppLayout,
        InstallationBanner
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
